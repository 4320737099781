<template>
  <div>
    <a-spin :spinning="loading">
      <!-- banner -->
      <a-carousel :autoplay="true">
        <div v-for="item in home.banners" :key="item.img">
          <img
            :src="item.img"
            style="height: 100%; width: 100%; -webkit-user-drag: none"
            alt=""
          />
        </div>
      </a-carousel>

      <!-- 产品列表 -->
      <div class="info-content">
        <div v-for="itemInfo in productList" :key="itemInfo.productGroupKey">
          <a-card v-if="itemInfo.productsList.length > 0" :bordered="false">
            <template slot="title">
              <h2>
                {{
                  $i18n.locale === 'enUS'
                    ? itemInfo.productGroupNameEn
                    : itemInfo.productGroupName
                }}
              </h2>
            </template>

            <!-- <template slot="extra">
                <router-link :to="{
                  path: '/product/small-module',
                  query: { locale: $i18n.locale },
                }">
                  <a>{{ $t('message')['home.more'] }}</a>
                </router-link>
              </template> -->

            <a-row>
              <a-col
                :span="5"
                :offset="1"
                v-for="item in itemInfo.productsList"
                :key="item.id"
              >
                <router-link
                  :to="{
                    path: '/product/small-module/detail/' + item.id,
                    query: { locale: $i18n.locale },
                  }"
                >
                  <a>
                    <a-card :bordered="false" :bodyStyle="{ padding: '0px' }">
                      <img
                        class="product-img"
                        :alt="$i18n.locale === 'enUS' ? item.nameEn : item.name"
                        :src="
                          $i18n.locale === 'enUS'
                            ? item.imageUrlEn
                            : item.imageUrl
                        "
                      />
                      <p class="product-title">
                        {{ $i18n.locale === 'enUS' ? item.nameEn : item.name }}
                      </p>
                    </a-card>
                  </a>
                </router-link>
              </a-col>
            </a-row>
          </a-card>
        </div>

        <!-- 公司介绍 -->
        <a-card :bordered="false" style="padding-top: 20px">
          <template slot="title">
            <h2>{{ $t('message')['home.companyIntroduce'] }}</h2>
          </template>

          <template slot="extra">
            <router-link
              :to="{
                path: '/about-us/company',
                query: { locale: $i18n.locale },
              }"
            >
              <a>{{ $t('message')['home.more'] }}</a>
            </router-link>
          </template>

          <a-row v-if="company">
            <a-col :span="5" :offset="1">
              <img
                style="width: 100%"
                alt="company_introduce"
                :src="company.companySmallPic"
              />
            </a-col>

            <a-col :span="18">
              <p
                v-for="item in company.introduce"
                :key="item"
                class="content-text"
              >
                {{ item }}
              </p>

              <p class="content-text">
                {{ $t('message')['home.productFeature'] }}
              </p>

              <p
                v-for="item in company.feature"
                :key="item"
                class="content-text"
              >
                {{ item }}
              </p>
            </a-col>
          </a-row>
        </a-card>

        <!-- 合作方案 -->
        <a-card :bordered="false" style="padding-top: 20px">
          <template slot="title">
            <h2>{{ $t('message')['home.cooperationScheme'] }}</h2>
          </template>

          <template slot="extra">
            <router-link
              :to="{
                path: '/about-us/cooperation',
                query: { locale: $i18n.locale },
              }"
            >
              <a>{{ $t('message')['home.more'] }}</a>
            </router-link>
          </template>

          <a-row>
            <a-col
              :span="5"
              :offset="1"
              v-for="item in cooperation"
              :key="item.title"
            >
              <a-card :bordered="false" :bodyStyle="{ padding: '0px' }">
                <img
                  style="width: 100%; height: 195px"
                  :alt="item.title"
                  :src="item.img"
                />
                <p style="text-align: center; margin-top: 15px; color: #333333">
                  {{ item.title }}
                </p>
              </a-card>
            </a-col>
          </a-row>
        </a-card>

        <!-- 服务特色 -->
        <a-card :bordered="false" style="padding-top: 20px">
          <template slot="title">
            <h2>{{ $t('message')['home.serviceFeatures'] }}</h2>
          </template>

          <a-row v-if="serviceFeature">
            <a-col :span="12" :offset="1">
              <p
                style="color: #4d4d4d; font-size: 16px"
                v-for="(item, index) in serviceFeature.feature"
                :key="item.title"
              >
                <span style="font-size: 20px">
                  {{ index + 1 }}、 {{ item.title }}：
                </span>
                {{ item.content }}
              </p>
            </a-col>

            <a-col :span="8" :offset="3">
              <img
                style="width: 100%"
                alt="company_introduce"
                :src="serviceFeature.img"
              />
            </a-col>
          </a-row>
        </a-card>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { dataZH, dataEN } from '@/utils/data'
import { getLanguage } from '@/utils/languageUtils'
import { getHomeProductList } from '@/api/product'
export default {
  name: 'AboutUs',
  data() {
    return {
      company: dataZH.company,
      home: dataZH.home,
      cooperation: dataZH.cooperation.slice(0, 4),
      serviceFeature: dataZH.serviceFeature,
      productList: [],
      loading: false,
    }
  },
  created() {
    this.changeLanguage(getLanguage(this.$route))
    this.getProductList()
  },
  methods: {
    // 获取产品列表
    getProductList() {
      this.loading = true
      getHomeProductList()
        .then((res) => {
          if (res.code === 200) {
            this.productList = res.rows
          } else if (res.code === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$message.error(err.message)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 更改语言
    changeLanguage(language) {
      this.company = language === 'enUS' ? dataEN.company : dataZH.company
      this.home = language === 'enUS' ? dataEN.home : dataZH.home
      this.serviceFeature =
        language === 'enUS' ? dataEN.serviceFeature : dataZH.serviceFeature
      let cooperation =
        language === 'enUS' ? dataEN.cooperation : dataZH.cooperation
      this.cooperation = cooperation.slice(0, 4)
    },
  },
  watch: {
    '$i18n.locale': function (val) {
      this.changeLanguage(val)
    },
  },
}
</script>

<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  /* height: 250px;
  line-height: 250px; */
  background: #364d79;
  overflow: hidden;
}

.info-content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
}

.content-text {
  color: #333333;
  line-height: 30px;
  text-indent: 2em;
}

.product-img {
  width: 100%;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
}

.product-img:hover {
  transform: scale(1.1, 1.1);
}

.product-title {
  text-align: center;
  padding: 15px 0 20px;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
}
</style>
