var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-carousel',{attrs:{"autoplay":true}},_vm._l((_vm.home.banners),function(item){return _c('div',{key:item.img},[_c('img',{staticStyle:{"height":"100%","width":"100%","-webkit-user-drag":"none"},attrs:{"src":item.img,"alt":""}})])}),0),_c('div',{staticClass:"info-content"},[_vm._l((_vm.productList),function(itemInfo){return _c('div',{key:itemInfo.productGroupKey},[(itemInfo.productsList.length > 0)?_c('a-card',{attrs:{"bordered":false}},[_c('template',{slot:"title"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'enUS' ? itemInfo.productGroupNameEn : itemInfo.productGroupName)+" ")])]),_c('a-row',_vm._l((itemInfo.productsList),function(item){return _c('a-col',{key:item.id,attrs:{"span":5,"offset":1}},[_c('router-link',{attrs:{"to":{
                  path: '/product/small-module/detail/' + item.id,
                  query: { locale: _vm.$i18n.locale },
                }}},[_c('a',[_c('a-card',{attrs:{"bordered":false,"bodyStyle":{ padding: '0px' }}},[_c('img',{staticClass:"product-img",attrs:{"alt":_vm.$i18n.locale === 'enUS' ? item.nameEn : item.name,"src":_vm.$i18n.locale === 'enUS'
                          ? item.imageUrlEn
                          : item.imageUrl}}),_c('p',{staticClass:"product-title"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'enUS' ? item.nameEn : item.name)+" ")])])],1)])],1)}),1)],2):_vm._e()],1)}),_c('a-card',{staticStyle:{"padding-top":"20px"},attrs:{"bordered":false}},[_c('template',{slot:"title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('message')['home.companyIntroduce']))])]),_c('template',{slot:"extra"},[_c('router-link',{attrs:{"to":{
              path: '/about-us/company',
              query: { locale: _vm.$i18n.locale },
            }}},[_c('a',[_vm._v(_vm._s(_vm.$t('message')['home.more']))])])],1),(_vm.company)?_c('a-row',[_c('a-col',{attrs:{"span":5,"offset":1}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"company_introduce","src":_vm.company.companySmallPic}})]),_c('a-col',{attrs:{"span":18}},[_vm._l((_vm.company.introduce),function(item){return _c('p',{key:item,staticClass:"content-text"},[_vm._v(" "+_vm._s(item)+" ")])}),_c('p',{staticClass:"content-text"},[_vm._v(" "+_vm._s(_vm.$t('message')['home.productFeature'])+" ")]),_vm._l((_vm.company.feature),function(item){return _c('p',{key:item,staticClass:"content-text"},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1):_vm._e()],2),_c('a-card',{staticStyle:{"padding-top":"20px"},attrs:{"bordered":false}},[_c('template',{slot:"title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('message')['home.cooperationScheme']))])]),_c('template',{slot:"extra"},[_c('router-link',{attrs:{"to":{
              path: '/about-us/cooperation',
              query: { locale: _vm.$i18n.locale },
            }}},[_c('a',[_vm._v(_vm._s(_vm.$t('message')['home.more']))])])],1),_c('a-row',_vm._l((_vm.cooperation),function(item){return _c('a-col',{key:item.title,attrs:{"span":5,"offset":1}},[_c('a-card',{attrs:{"bordered":false,"bodyStyle":{ padding: '0px' }}},[_c('img',{staticStyle:{"width":"100%","height":"195px"},attrs:{"alt":item.title,"src":item.img}}),_c('p',{staticStyle:{"text-align":"center","margin-top":"15px","color":"#333333"}},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)}),1)],2),_c('a-card',{staticStyle:{"padding-top":"20px"},attrs:{"bordered":false}},[_c('template',{slot:"title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('message')['home.serviceFeatures']))])]),(_vm.serviceFeature)?_c('a-row',[_c('a-col',{attrs:{"span":12,"offset":1}},_vm._l((_vm.serviceFeature.feature),function(item,index){return _c('p',{key:item.title,staticStyle:{"color":"#4d4d4d","font-size":"16px"}},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(index + 1)+"、 "+_vm._s(item.title)+"： ")]),_vm._v(" "+_vm._s(item.content)+" ")])}),0),_c('a-col',{attrs:{"span":8,"offset":3}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"company_introduce","src":_vm.serviceFeature.img}})])],1):_vm._e()],2)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }