import request from '@/utils/request'

const productApi = {
  HomeProductList: '/website/home/product/list',
  ProductList: '/website/product/list',
  ProductDetail: '/website/product/',
}

// 获取首页产品列表
export function getHomeProductList() {
  return request({
    url: productApi.HomeProductList,
    method: 'get',
  })
}

// 获取产品列表
export function getProductList(query = {}) {
  return request({
    url: productApi.ProductList,
    method: 'get',
    params: query,
  })
}

// 获取Nordic产品列表
export function getNordicProductList(query = {}) {
  return request({
    url: productApi.ProductList,
    method: 'get',
    params: {
      ...query,
      productGroup: 'nordic',
    },
  })
}

// 获取Dialog产品列表
export function getDialogProductList(query = {}) {
  return request({
    url: productApi.ProductList,
    method: 'get',
    params: {
      ...query,
      productGroup: 'dialog',
    },
  })
}

// 获取Lora产品列表
export function getLoraProductList(query = {}) {
  return request({
    url: productApi.ProductList,
    method: 'get',
    params: {
      ...query,
      productGroup: 'lora',
    },
  })
}

// 获取产品详情
export function getProductDetail(id) {
  return request({
    url: productApi.ProductDetail + id,
    method: 'get',
  })
}
